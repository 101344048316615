import React from 'react'
import '../components/style.css'
import Footer from '../components/Footer'

const About = () => {
return (
<div>
<section>
    <img src="https://media.istockphoto.com/id/1385970223/photo/great-idea-of-a-marketing-strategy-plan-at-a-creative-office.jpg?b=1&s=170667a&w=0&k=20&c=tb2d0EOX_j10LHI_1znMGoSI_55FBSwP-Mo68GMzgfY=" className="image-fluid businessImage"  style={{width:"100%"}}alt="" />
</section>
<section className="py-4">
    <h3 className="text-center">About Us</h3>
    <div className="row container">
        <div className="col-md-8 m-auto">
            <p className="lead">
                <ul className='mb-3'>
                    <li><h4><i>Our Mission:</i></h4>Through the provision of context-specific technical support, Jaeda 
                    & associates Ltd enables development and emergency-oriented organizations to accomplish their vision, purpose and objectives</li>
                </ul>
                <ul className='mb-3'>
                    <li><h4><i>Our Vision:</i></h4>Being a pemier global consulting firm that provides state-of-the-art monitoring, assessing and research services focused on specifically assisting our
                    clients in obtaining pertinent data that can be applied to program enhancement</li>
                </ul>
            </p>
        </div>
        </div>
        <div className="container mt-3">
            <div className="row">
                <div className="col-md-6">
                    <h4>Core Values</h4>
                    <p className="lead">Jaeda & associates Ltd. upholds honesty, professional ethics, inclusivity, responsiveness and
                    responsibility in order to successfully carry out our purpose</p>
                </div>
                <div className="col-md-6">
                <h4>Registration</h4>
                    <p className="lead">Our registration number under the Companies Act of Kenya is PVT-KAUZ3MM9. We conduct business both domestically and abroad</p>
                </div>
                </div>
            </div>
</section>
<Footer/>
</div>
)
}

export default About