import React from 'react'
import Footer from './Footer'

const Import = () => {
  return (
    <div>
      <section>
    <img src="https://images.unsplash.com/photo-1592963219751-3800a144a41e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aW1wb3J0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60" className="image-fluid businessImage"  style={{height:"500px",width:"100%"}}alt="" />
    </section>
    <section data-aos="fade-up" className="py-3">
      <h3 className="text-center">Import Support</h3>
      <div className="container">
          <p className="lead">At Jaeda & Associates, we provide services to assist companies and individuals with the process of importing goods from foreign countries. These services include assistance with customs clearance, logistics, compliance with regulations and laws, and other related tasks. We also provide consulting services to help clients navigate the complexities of international trade, such as identifying potential suppliers, negotiating contracts, and managing logistics. Here at Jaeda & Associates, we provide services for a wide range of goods and industries.</p>
      </div>
    </section>
    <Footer/>
    </div>
  )
}

export default Import