import React from 'react'
import Footer from './Footer'

const Merchandising = () => {
  return (
    <div>
      <section>
      <img src="https://media.istockphoto.com/id/1385196919/photo/inflation-concept.jpg?b=1&s=170667a&w=0&k=20&c=kaezh3QQC46Tf9XBtBehTfFlg-TyIoma-8wtPcWIJJI=" className="image-fluid businessImage"  style={{width:"100%"}}alt="" />
      </section>
      <section data-aos="fade-up" className="py-3 container">
      <h3 className="text-center">Merchandising</h3>
          <p className="lead">We are offering a wide range of farm machinery and containers for sale. Our current selection includes 20ft, 40ft low cube and 40ft high cube containers. If you are looking for something specific, we also offer customised fabricated containers to meet your unique needs. Additionally, we have refrigerated containers available in both 20ft and 40ft sizes.</p>
    </section>
      <Footer/>
    </div>
  )
}

export default Merchandising