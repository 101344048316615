import React from 'react'
import { Nav, Navbar, Container } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import logo from  '../static/logo.png'
import {Link} from 'react-router-dom'
import './navigation.css'
import ReactWhatsapp from 'react-whatsapp';

const Navigation = () => {
return (
    <>
    <div style={{background:"#F1EBE6"}} className=' py-2'>
    <div className="row container">
        <div className="col-md-6">
            <p style={{fontStyle:"oblique", color:"rgb(198, 67, 19)"}}>Jaeda & Associates Limited</p>
        </div>
        <div className="col-md-6 text-center " >
            <a className='px-3' href="https://twitter.com/JaedaAssociates" target="blank"><i style={{color: "rgb(198, 67, 19)"}}  class="fab fa-twitter "></i></a>
            <a className='px-3' href="https://www.facebook.com/people/Jaeda-Associates/100068246903727" target="blank"><i style={{color: "rgb(198, 67, 19)"}} class="fab fa-facebook-f "></i></a>
            <a className='px-3' href="https://wa.me/254702635434" target="blank"><i style={{color:"rgb(198, 67, 19)"}} class="fab fa-whatsapp"></i><ReactWhatsapp className="d-none" number="254702635434" message="www.associatesjaeda.com" /></a>
            <a className='px-3' href="https://www.linkedin.com/company/jaeda-associates/about/?viewAsMember=true" target="blank"><i style={{color: "rgb(198, 67, 19)"}} class="fab fa-linkedin "></i></a>
            <a className='px-3' href="mailto:associatesjaeda@gmail.com" target="blank"><i style={{color:"rgb(198, 67, 19)"}} className="fas fa-envelope" /></a>
            <a className='px-3' href="tel:+254702635434" target="blank"><i style={{color:"rgb(198, 67, 19)"}} className="fas fa-phone" /></a>
            <a className='px-3' href="https://t.me/+cNRqtSqQH4RkODA8" target="blank"><i style={{color: "rgb(198, 67, 19)"}} class="fab fa-telegram "></i></a>
        </div>
    </div>
    </div>
    
    <Navbar bg="light" expand="lg" className="Nav-body">
        <Container>
            <LinkContainer to="/about">
                <Navbar.Brand>
                    <img src={logo} alt="" style={{ width: "150px", height: "80px" }} className="animate__animated animate__backInLeft" />
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto animate__animated animate__backInRight">
                <Nav.Link>
                        <div className="dropdown products">
                            <div className="dropbtn"><Link style={{textDecoration:"none",color:"black"}} to="/">Home</Link></div>
                        </div>
                    </Nav.Link>
                    <Nav.Link>
                        <div className="dropdown products">
                            <div className="dropbtn"><Link style={{textDecoration:"none",color:"black"}}>Services</Link></div>
                            <div className="dropdown-content">
                                <p><Link style={{textDecoration:"none",color:"black"}} to="/services/business_development">Business Development</Link></p>
                                <p><Link style={{textDecoration:"none",color:"black"}} to="/services/distribution_&_marketing">Distribution and Marketing</Link></p>
                                <p><Link style={{textDecoration:"none",color:"black"}} to="/services/import">Import Support</Link></p>
                                <p><Link style={{textDecoration:"none",color:"black"}} to="/services/merchandising">Merchandising</Link></p>
                                <p><Link style={{textDecoration:"none",color:"black"}} to="/services/supplies">General Supplies</Link></p>
                            </div>
                        </div>
                    </Nav.Link>
                    <Nav.Link>
                        <div className="dropdown products">
                            <div className="dropbtn"><Link style={{textDecoration:"none",color:"black"}} to="/resources">Resources</Link></div>
                            <div className="dropdown-content">
                                <p><Link style={{textDecoration:"none",color:"black"}} to="/resources/news">News</Link></p>
                            </div>
                        </div>
                    </Nav.Link>
                    <LinkContainer to="/contact">
                        <Nav.Link style={{color:"black"}}>Contact Us</Nav.Link>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    </>
)
}

export default Navigation