import React from 'react'
import Footer from './Footer'
import './style.css'

const Distribution = () => {
  return (
    <div>
      <section>
    <img src="https://images.unsplash.com/photo-1455849318743-b2233052fcff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGRpc3RyaWJ1dGlvbiUyMGFuZCUyMG1hcmtldGluZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60" className="image-fluid businessImage"  style={{width:"100%"}}alt="" />
    </section>
    <section data-aos="fade-up" className="py-3">
      <h3 className="text-center">Distribution and Marketing</h3>
      <div data-aos="fade-up" className=" container">
          <p className="lead">Jaeda & Associates is a business consulting firm that specializes in providing solutions to complex problems. Our team of experts is highly knowledgeable in analyzing situations and understanding organizational objectives. We are able to think both creatively and analytically in order to develop the best possible solution for our clients. With our dedication to excellence, Jaeda & Associates is the perfect partner for any business looking to tackle their toughest challenges.</p>
      </div>

    </section>
    <Footer/>
    </div>
  )
}

export default Distribution