import React from 'react'
import './footer.css'
import {Link} from 'react-router-dom'
import logo from  '../static/logo.png'


const Footer = () => {
return (
<div className='position-relative'>
    <div>
        <section style={{color:"black", background:"white",borderBottom:"1px solid black"}} className="text-center">
        <div className="text-center py-4 container">
            <Link to="/about" style={{color:"black", textDecoration: "none"}}><h4>JAEDA & ASSOCIATES LTD </h4></Link>
            <Link to="/contact" style={{color:"rgb(198, 67, 19)"}}>Get in Touch</Link>
        </div>
        </section>
    </div>
<footer class="footer" >
    <div class="container">
    <div  class="row">
        <div class="footer-col">
            <h4>company</h4>
            <ul>
                <li><a href="#"><Link to="/about" >jaeda & Associates ltd.</Link></a></li>
                <li><a href="#"><Link to="/contact" >contact us</Link></a></li>
                <li><a href="#"><Link to="/resources/news" >news</Link></a></li>
            </ul>
        </div>
        <div class="footer-col">
            <h4>Products and Services</h4>
            <ul>
                <li><a href="#"><Link to="/products/business_development">Business Development</Link></a></li>
                <li><a href="#"><Link to="/products/distribution_&_marketing">Distribution and Marketing</Link></a></li>
                <li><a href="#"><Link to="/products/import">Import Support</Link></a></li>
                <li><a href="#"><Link to="/products/merchandising">Merchandising</Link></a></li>
                <li><a href="#"><Link to="/products/supplies">general Suppplies</Link></a></li>
            </ul>
        </div>
        <div class="footer-col">
            <h4>Location</h4>
            <ul>
                <li><a style={{fontSize:"12px"}} href="#">Jaeda & associates Ltd</a></li>
                <li><a style={{fontSize:"12px"}} href="#">Casuarina Plaza, off Jogoo Road</a></li>
                <li><a style={{fontSize:"12px"}} href="#">P.O BOX 9494 00300,Nairobi, Kenya</a></li>
                <li><a style={{fontSize:"12px"}} href="tel:+254702635434">Tel: +254702635434</a></li>
                <li><a style={{fontSize:"12px", textTransform:"lowercase"}} href="mailto:associatesjaeda@gmail.com"><b>Email</b> : associatesjaeda@gmail.com</a></li>
            </ul>
        </div>
        <div class="footer-col">
            <h4>follow us</h4>
            <div class="social-links">
                <a  href="https://twitter.com/JaedaAssociates" target="blank"><i  class="fab fa-twitter "></i></a>
                <a href="https://www.facebook.com/people/Jaeda-Associates/100068246903727" target="blank"><i class="fab fa-facebook-f"></i></a>
                <a href="https://www.linkedin.com/company/jaeda-associates/about/?viewAsMember=true" target="blank"><i class="fab fa-linkedin-in"></i></a>
                <a href="https://t.me/+cNRqtSqQH4RkODA8" target="blank"><i  class="fab fa-telegram "></i></a>
            </div>
        </div>
    </div>
    </div>
</footer>
<div  className="text-center" style={{backgroundColor:"#283b8e"}}>
    <h5 className="text-light">&copy; 2023</h5>
    <a href="#top" className="position-absolute bottom-0 end-0 p-3">
        <i style={{color: 'white'}} className="bi bi-arrow-up-circle h1"></i>
    </a>
</div>
</div>
)
}

export default Footer