import React from 'react'
import Footer from './Footer'

const Supplies = () => {
  return (
    <div>
      <section>
      <img src="https://media.istockphoto.com/id/1400287455/photo/generic-50-percent-off-coupon-voucher-promo-qr-code-template-mockup-smartphone-display-screen.jpg?s=612x612&w=0&k=20&c=DAJCvpO0isf9zuRhtOp6hJAo0aiOTMaRTFzG0JfVEQs=" className="image-fluid businessImage"  style={{width:"100%"}}alt="" />
      </section>
      <section className="py-3">
      <h3 className="text-center">General Supplies</h3>
      <div data-aos="fade-up" className=" container">
          <p>At Jaeda & Associates, we offer a wide range of services and these services include:</p>
          <ul>
            <li><b>Product sourcing:</b> We are involved in identifying and sourcing products from various suppliers to meet the needs of our customers</li>
            <li><b>Logistics and Distribution:</b> We manage the transportation and delivery of products to our customers</li>
            <li><b>Training and Education:</b> We are involved in providing training and education about the use of products to our customers and how to maintain the products we sell</li>
            <li><b>Installation and Maintenance:</b> We offer installation and maintenance for products that require installation</li>
            <li><b>Technical support:</b> We are involved in offering technical support for the products that we sell</li>
          </ul>
      </div>
    </section>
      <Footer/>
    </div>
  )
}

export default Supplies