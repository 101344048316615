import React from 'react'
import './home.css'
import chai from '../static/chaidiamond.png'
import Footer from '../components/Footer'
import ReactWhatsapp from 'react-whatsapp';


const Home = () => {

  return (
    <div className="Body " id="top">
      <section  id="home" className="image-fluid">
      <a href="https://wa.me/254702635434"  target="blank" className="whatsapp_float"><i className="fa-brands fa-whatsapp whatsapp-icon"></i><ReactWhatsapp className="d-none" number="254702635434" message="www.associatesjaeda.com" /></a>
        <div className="container" >
          <div className="row">
            <div className="home-text">
            <h1 className="fw-bold fs-50 bigtext animate__animated animate__backInLeft">Create Your Own <br/> World with Jaeda & Associates ltd</h1>
            <p className="lead smalltext animate__animated animate__backInRight ">Convert Your Work Into Profit With World most secure platform</p>
            </div>
          </div>
          <div className="overlay"></div>
        </div>
      </section>
      <section data-aos="fade-up" className="py-3 container">
        <h3 className="text-center">Company Profile</h3>
        <p>Since its founding in 2019, Jaeda & associates Limited has grown to become one of Kenya's top business, actively
                engaged in the production and distribution of clothing, goods merchandising, Import support, facilities management,
                land sales, training and development and financial advisory services</p>
      </section>
      <Footer/>
    </div>
  )
}

export default Home