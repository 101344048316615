import React from 'react'
import './product.css'
import {Link} from 'react-router-dom'
import Footer from '../components/Footer'

const Product = () => {
  return (
    <div>
        <section id="product">
          <div className="container">
            <div className="row">
              <div className="text-light col-md-6">
              <h1 className="fw-bold fs-50">Market Better, Collaborate <br/> Smarter and sell Faster</h1>
              <p  className="lead ">Jaeda & Associates brings the power of Business Development, Distribution & Marketing, Import support, Merchandising and General supplies with a comprehensive suite of easy-to-use solutions to get your Business to market faster than ever before</p>
              </div>
            </div>
            <div className="overlay"></div>
            </div>
        </section>
        <section  className="py-4 ">
          <div data-aos="fade-up" className="row container py-3">
            <div data-aos="fade-left" className="col-md-6 g-3 text-center">
              <img src="https://images.unsplash.com/photo-1537861295351-76bb831ece99?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGJ1c2luZXNzJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60" className="image-fluid productImage"  alt="" />
            </div>
            <div data-aos="fade-right" className="col-md-6 g-3">
              <h3>Business Management</h3>
              <p className="lead">At Jaeda & Associates our main responsibility is Setting goals and developing plans for business and revenue growth. Researching, planning, and implementing new target market initiatives. Researching prospective accounts in target markets. Pursuing leads and moving them through the sales cycle. Developing quotes and proposals for prospective clients.</p>
              <Link to="/products/business_development"><p className="btn btn-warning"> Learn More</p></Link>
            </div>
          </div>
          <div data-aos="fade-up" className="row container py-3">
            <div className="col-md-6 g-3">
              <h3>Distribution and Marketing</h3>
              <p className="lead">At Jaeda & Associates we are responsible for coordinating the storage, transportation and delivery of goods.  We are responsible  for overseeing and liaising with colleagues to ensure stock is maintained and moved efficiently.</p>
              <Link to="/products/distribution_&_marketing"><p className="btn btn-warning"> Learn More</p></Link>
            </div>
            <div className="col-md-6 g-3 text-center">
              <img src="https://images.unsplash.com/photo-1455849318743-b2233052fcff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGRpc3RyaWJ1dGlvbiUyMGFuZCUyMG1hcmtldGluZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=600&q=60" className="image-fluid productImage"  alt="" />
            </div>
          </div>
          <div data-aos="fade-up" className="row container py-3">
            <div className="col-md-6 g-3 text-center">
              <img src="https://images.unsplash.com/photo-1592963219751-3800a144a41e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8aW1wb3J0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=600&q=60" className="image-fluid productImage"  alt="" />
            </div>
            <div className="col-md-6 g-3">
              <h3>Import Support</h3>
              <p className="lead">We foresee the movement of goods between the home country and other countries. Jaeda & Associates Managers manage the import coming to the home country with the general duties at the office that Import responsibilities come with.</p>
              <Link to="/products/import"><p className="btn btn-warning"> Learn More</p></Link>
            </div>
          </div>
          <div data-aos="fade-up" className="row container py-3">
            <div className="col-md-6 g-3">
              <h3>Merchandising</h3>
              <p className="lead">As Jaeda & Associates, We are fully responsible for Planning and developing merchandising strategies. Analyzing sales figures, customers reactions and market trends to anticipate product needs. Collaborating with buyers, suppliers, distributors and analysts to negotiate prices, quantities and time-scales.</p>
              <Link to="/products/merchandising"><p className="btn btn-warning"> Learn More</p></Link>
            </div>
            <div className="col-md-6 g-3 text-center">
              <img src="https://media.istockphoto.com/id/1385196919/photo/inflation-concept.jpg?b=1&s=170667a&w=0&k=20&c=kaezh3QQC46Tf9XBtBehTfFlg-TyIoma-8wtPcWIJJI=" className="image-fluid productImage"  alt="" />
            </div>
          </div>
          <div data-aos="fade-up" className="row container py-3">
            <div className="col-md-6 g-3 text-center">
              <img src="https://media.istockphoto.com/id/1404612229/photo/the-stack-containers-for-market.jpg?b=1&s=170667a&w=0&k=20&c=U5Jh3fjASK6V5WK3_GQb0b8jv1uUr74xEUkONRmaLY0=" className="image-fluid productImage"  alt="" />
            </div>
            <div className="col-md-6 g-3">
              <h3>General Supplies</h3>
              <p className="lead">As Jaeda & Associates we are incharge of supplies, sells, offers or exposes for sale, leases, distributes and to provide high-quality products from a our manufacturers at a good price to a our clients for resale.</p>
              <Link to="/products/supplies"><p className="btn btn-warning"> Learn More</p></Link>
            </div>
          </div>
        </section>
        <Footer/>
    </div>
  )
}

export default Product