import React from 'react'
import Footer from './Footer'
import './style.css'

const Business = () => {
return (
<div>
    <section>
    <img src="https://images.unsplash.com/photo-1537861295351-76bb831ece99?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGJ1c2luZXNzJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8&auto=format&fit=crop&w=600&q=60" className="image-fluid businessImage"  style={{width:"100%"}}alt="" />
    </section>
    <section data-aos="fade-up" className="py-3">
        <div data-aos="fade-up" className="container">
                <h3 className="text-center">Business Development</h3>
                <p className='lead'>Businesses that use Jaeda & Associates are assured of fully established and maintained relationships with prospects, learn about their buyer's personas, increase brand awareness and new Opportunities to promote Growth.
                In contrast, our sales team sell your product or service to customers and work to convert leads into customers simplifying the work of a salesperson or sales manager</p>
        </div>
        <div data-aos="fade-up" className="row container">
            <div className="col-md-6">
                <p className='lead'><b>By joining Jaeda & Associates, we will be responsible for:</b></p>
                <ol>
                    <li>Setting goals and developing plans for your business and revenue growth</li>
                    <li>Researching, planning, and implementing new target market initiatives</li>
                    <li>Researching prospective accounts in target markets</li>
                    <li>Pursuing leads and moving them through the sales cycle</li>
                    <li>Developing quotes and proposals for prospective clients</li>
                    <li>Training business development staff</li>
                </ol>
            </div>
            <div className="col-md-6">
            <p className='lead'><b>By joining Jaeda & Associates, you benefit the following:</b></p>
            <ol >
                <li>We will ensure that we communicate with your clients, of all backgrounds and educational levels, appropriately to get a wider market </li>
                <li>You are assured of Technical and Computer Skills.We will use Content management system (CMS) to track leads and client calls and document where each prospect is in the sales cycle</li>
                <li>Improvement in your company's overall image.Through our market strategic plan and research </li>
            </ol>
            </div>
        </div>
        <div data-aos="fade-up" className="row">
            <div className="col-md-6 m-auto">
                <h6 className="text-center">We offer business development ideas in a number of industries, including:</h6>
                <ul className="text-center">
                    <li>E-commerce</li>
                    <li>Construction</li>
                    <li>Staffing</li>
                </ul>
            </div>
        </div>
    </section>
    <Footer/>
</div>
)
}

export default Business