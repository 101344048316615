import React,{ useRef, useState  } from 'react'
import './contact.css'
import {Alert, Accordion,Form, Button} from 'react-bootstrap'
import Footer from '../components/Footer'
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
    const [show, setShow] = useState(false);
    const sendEmail = (e) => {
        e.preventDefault();
    
    emailjs.sendForm('service_qq6o6ir', 'template_og7djc5', form.current, 'pMjVuyMRr1x2EsAqn')
        .then((result) => {
            console.log(result.text);
            setShow(true)
        }, (error) => {
            console.log(error.text);
        });
    };
return (
<div className="mainContact">
    <section id="contactHome" className="pb-5">
        <img src="https://media.istockphoto.com/id/1147449046/photo/business-development-concept-finding-solution-to-growth.jpg?b=1&s=170667a&w=0&k=20&c=WFOHKMm51RQjdCX6BCAtgPMrSHFXVhmFC3qL6V215GQ=" className="image-fluid contactImage" alt="" style={{ width:"100%"}} />
        <div className="row container text-center pt-3">
            <div className="col-2">
                <h4 className="animate__animated animate__flip"><a href="https://twitter.com/JaedaAssociates" target="blank"><i style={{color: "rgb(198, 67, 19)"}}  class="fab fa-twitter "></i></a></h4>
            </div>
            <div className="col-2">
                <h4 className="animate__animated animate__flip"><a href="https://www.facebook.com/people/Jaeda-Associates/100068246903727" target="blank"><i style={{color:"rgb(198, 67, 19)"}} class="fab fa-facebook-f"></i></a></h4>
            </div>
            <div className="col-2">
                <h4 className="animate__animated animate__flip"><a href="https://wa.me/254702635434" target="blank"><i style={{color:"rgb(198, 67, 19)"}} class="fab fa-whatsapp"></i></a></h4>
            </div>
            <div className="col-2">
                <h4 className="animate__animated animate__flip"><a href="https://www.linkedin.com/company/jaeda-associates/about/?viewAsMember=true" target="blank"><i style={{color:"rgb(198, 67, 19)"}} class="fab fa-linkedin-in"></i></a></h4>
            </div>
            <div className="col-2">
                <h4 className="animate__animated animate__flip"><a href="tel:+254702635434" target="blank"><i style={{color:"rgb(198, 67, 19)"}} className="fas fa-phone" /></a></h4>
            </div>
            <div className="col-2">
                <h4 className="animate__animated animate__flip"><a href="mailto:associatesjaeda@gmail.com" target="blank"><i style={{color:"rgb(198, 67, 19)"}} className="fas fa-envelope" /></a></h4>
            </div>
        </div>
        <div data-aos="fade-up" className="row container mt-4">
            <div className="col-md-6">
            {show && <Alert variant='success' onClose={() => setShow(false) } dismissible>
                <Alert.Heading className="text-center">Message sent!!</Alert.Heading>
            </Alert>}
                <h4 className="pt-4 animate__animated animate__bounce ">CONTACT US TODAY!</h4>
                <p className="lead">We are extremely interested in hearing from you. Use the contact form below to send us any comments, suggestions
                or other information pertaining to our operations. Our addresses  and phone number are also listed at the bottom or send us an Email</p>
                <Form className="pt-4" ref={form} onSubmit={sendEmail}>
                    <div className="row mb-3">
                        <div className="col-sm-6 g-3">
                            <Form.Group>
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control type="text" placeholder='First Name' name='First_Name' required></Form.Control>
                            </Form.Group>
                        </div>
                        <div className="col-sm-6 g-3">
                        <Form.Group>
                            <Form.Label>Last Name*</Form.Label>
                            <Form.Control type="text" placeholder='Last Name' name='Last_Name' required></Form.Control>
                        </Form.Group>
                        </div>
                    </div>
                    <Form.Group className="mb-3">
                        <Form.Label>Business Email*</Form.Label>
                        <Form.Control type="email" placeholder='Business Email' name='email' required></Form.Control>
                    </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Reason for Contact*</Form.Label>
                    <Form.Control type="text" placeholder='Reason for Contact' name='Reason_For_Contact' required></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Phone Number*</Form.Label>
                    <Form.Control type="number" placeholder='Phone Number' name='PhoneNumber' required></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Message*</Form.Label>
                    <Form.Control type="text" placeholder='Message' name='Message' required></Form.Control>
                </Form.Group>
                <p className="lead">Jaeda & Associates needs the contact information you provide to us to contact you about our products and services.</p>
                <Form.Group className="mb-3">
                    <Button type="submit" className='btn btn-warning text-light px-4 py-1'>Contact Jaeda</Button>
                </Form.Group>
                </Form>
            </div>
            <div className="col-md-6">
            <div>
                <h3 className=' animate__animated animate__bounce '> Contact Support </h3>
                <p className="lead">Looking for product support? Contact our <a href="Tel:+254702635434" target="blank" style={{textDecoration:"none",color:"rgb(198, 67, 19)"}}> Help Personell</a> for comminuty support,
                open a business support ticket and find product tips and best practices </p>
            </div>
            <div className="card text-center">
                <h4 className="pt-4">JAEDA & ASSOCIATES'S OFFICES</h4>
                <div className="">
                <ul >
                    <li>Casuarina Plaza, off Jogoo Road</li>
                    <li>P.O BOX 9494 00300, Nairobi, Kenya</li>
                    <li>Tel: +254702 635 434</li>
                    <li>Email: associatesjaeda@gmail.com</li>
                </ul>
                </div>
            </div>
            </div>
        </div>
    </section>
    <section data-aos="fade-up" className="pb-4">
        <h2 className="text-center">FAQS</h2>
        <div className="row">
            <div className="col-md-8 m-auto">
                <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>About Jaeda & Associates?</Accordion.Header>
                <Accordion.Body>
                We are a full-service marketing company dedicated to helping businesses grow and succeed. Our services include import support, business development, merchandising, distribution, and marketing. Our team of experienced professionals are committed to providing the highest quality of service to our clients. We understand the importance of creating and maintaining strong relationships with our clients and strive to provide them with the best possible solutions for their business needs. Our goal is to help our clients reach their full potential and achieve success. We are committed to providing the highest level of customer service and satisfaction. We look forward to working with you and helping you reach your business goals.
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            </div>
        </div>
    </section>
    <Footer/>
</div>
)

}

export default Contact