import React from 'react'
import './style.css'
import Footer from './Footer'
import news1 from '../static/chaidiamond.png'
const News = () => {
return (
<div id="news" >
<div id="homeNews" >
    <div className="newsText container">
    <h1 className="text-center">LATEST NEWS</h1>
    <p className="text-center lead fw-4">Our experts keeping you up to date with our ever changing industry</p>
    </div>
</div>
<div className='pt-3 container'>
        <div className="text-center">
        <img src={news1} style={{width:"200px", height:"150px"}} alt="" />
        </div>
        <h5 className="text-center">Partnership with Chai Diamond investment Ltd</h5>
        <p>Jaeda & Associates is proud to announce the partnership with Chai Diamond investment Ltd.
We are contracted to dispose land for current ongoing projects in MURANG'A COUNTY & BOMET COUNTY </p>
    
</div>
    <Footer/>
</div>
)
}

export default News