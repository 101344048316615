import { BrowserRouter, Routes, Route } from "react-router-dom";
import {useEffect} from 'react'
import Navigation from './components/Navigation'
import Home from './pages/Home'
import Contact from './pages/Contact'
import Product from './pages/Product'
import Supplies from './components/Supplies'
import Merchandising from './components/Merchandising'
import Import from './components/Import'
import Distribution from './components/Distribution'
import Business from './components/Business'
import About from './pages/About'
import News from './components/News'
import Aos from 'aos'
import 'aos/dist/aos.css'
function App() {
  useEffect(()=>{
    Aos.init({duration: 2000});
  },[])
  return (
    <BrowserRouter>
      <Navigation/>
      <Routes>
      <Route path='/' element={<Home/>} />
      <Route path="/about" element={<About/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/services" element={<Product/>} />
      <Route path="/services/supplies" element={<Supplies/>}/>
      <Route path="/services/merchandising" element={<Merchandising/>}/>
      <Route path="/services/import" element={<Import/>}/>
      <Route path="/services/distribution_&_marketing" element={<Distribution/>}/>
      <Route path="/services/business_development" element={<Business/>}/>
      <Route path="/resources/news" element={<News/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
